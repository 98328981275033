
















import { Component, Vue, Prop } from 'vue-property-decorator'
import firebase from '@/plugins/firebase'
import { Device } from '@/models/device'
import AuthStore from '@/store/AuthStore'
import groupCollection from '@/models/group'

@Component({
  components: {}
})
export default class DeviceItemGroup extends Vue {
  @Prop({ type: Object, required: true }) doc!: firebase.firestore.QueryDocumentSnapshot<Device>

  get item (): Device {
    return this.doc.data()
  }

  get selectGroups (): { value: string, text: string }[] {
    return AuthStore.groups.map(doc => {
      return { value: doc.id, text: doc.data()?.name || '' }
    })
  }

  get group (): string | null {
    if (!this.item.group) return null
    const group = AuthStore.groups.find(item => item.id === this.item.group?.id)
    return group ? group.id : null
  }

  update (val: string): void {
    const group = groupCollection.doc(val)
    this.doc.ref.set({ group }, { merge: true })
  }
}









































import { Component, Vue, Prop } from 'vue-property-decorator'
import firebase from '@/plugins/firebase'
import { Device } from '@/models/device'
import DisplayTime from '@/components/common/DisplayTime.vue'
import DeviceItemCompany from '@/components/manage/DeviceItemCompany.vue'
import DeviceItemGroup from '@/components/manage/DeviceItemGroup.vue'

@Component({
  components: {
    DisplayTime,
    DeviceItemCompany,
    DeviceItemGroup
  }
})
export default class DeviceItem extends Vue {
  @Prop({ type: Object, required: true }) doc!: firebase.firestore.QueryDocumentSnapshot<Device>

  get item (): Device {
    return this.doc.data()
  }

  get toDashboard (): string {
    return `/dashboard/${this.doc.id}`
  }

  remove (): void {
    const r = confirm('정말 삭제 하시겠습니까?')
    if (!r) return
    this.doc.ref.delete()
  }
}


















import { Component, Vue, Prop } from 'vue-property-decorator'
import firebase from '@/plugins/firebase'
import { Device } from '@/models/device'
import AuthStore from '@/store/AuthStore'
import companyCollection from '@/models/company'

@Component({
  components: {}
})
export default class UserItemCompany extends Vue {
  @Prop({ type: Object, required: true }) doc!: firebase.firestore.QueryDocumentSnapshot<Device>

  get item (): Device {
    return this.doc.data()
  }

  get selectCompanies (): { value: string, text: string }[] {
    return AuthStore.companies.map(doc => {
      return { value: doc.id, text: doc.data()?.name || '' }
    })
  }

  get company (): string | null {
    if (!this.item.company) return null
    const company = AuthStore.companies.find(company => company.id === this.item.company?.id)
    return company ? company.id : null
  }

  update (val: string): void {
    const company = companyCollection.doc(val)
    this.doc.ref.set({ company }, { merge: true })
  }
}
